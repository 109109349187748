<script setup>
definePageMeta({
  middleware: ['not-authenticated'],
})

const configurationStore = useConfigurationStore()
const route = useRoute()
const { login } = useStrapiAuth()

const form = reactive({
  identifier: '',
  password: '',
})
const errorMessage = ref('')

const configuration = computed(() => configurationStore.configuration)

async function submit() {
  try {
    await login(form)
    errorMessage.value = ''

    if (route.query?.redirectAfterLogin) {
      const redirectAfterLogin = route.query.redirectAfterLogin
      return navigateTo(redirectAfterLogin)
    } else {
      return navigateTo('/cockpit')
    }
  } catch ({ error }) {
    errorMessage.value = 'Ongeldige combinatie email en wachtwoord'
  }
}
</script>

<template>
  <div class="min-h-area py-20">
    <Container>
      <div class="mx-auto md:max-w-sm">
        <form @submit.prevent="submit">
          <div
            class="rounded-lg border border-gray-200 bg-white p-10 shadow-md shadow-gray-300"
          >
            <h1
              class="mb-6 text-center text-7xl font-black uppercase text-secondary"
            >
              Inloggen
            </h1>

            <div v-if="configuration?.loginEnabled" class="mb-4">
              <Input
                v-model="form.identifier"
                :error="errorMessage.length"
                class="mb-2"
                placeholder="Emailadres"
                value=""
              />
              <Input
                v-model="form.password"
                :error="errorMessage.length"
                type="password"
                placeholder="Wachtwoord"
                value=""
              />
            </div>

            <Notice
              v-if="errorMessage"
              type="error"
              class="mb-4"
              @close="errorMessage = ''"
            >
              {{ errorMessage }}
            </Notice>

            <NuxtLink
              class="text-base text-secondary"
              :to="{ name: 'password-forgot' }"
            >
              Wachtwoord vergeten
            </NuxtLink>

            <div v-if="configuration?.loginEnabled" class="mt-6">
              <Button
                theme="primary"
                text="Inloggen"
                size="md"
                class="mb-4 w-full"
                type="submit"
              />
            </div>

            <div class="text-center">
              <span class="text-gray-600">Nog geen account?</span>
              <NuxtLink :to="{ name: 'register' }" class="text-secondary"
                >Registreer hier</NuxtLink
              >
            </div>
          </div>
        </form>
      </div>
    </Container>
  </div>
</template>
